import {
  UPDATE_CYCLE_FILTER_ATTRIBUTES,
  REMOVE_CYCLE_FILTERS,
} from "actions/constants";

const initialState = {
  filter_view_type: "cycle",
  global_filters: {},
  cycle_view: {},
};

const goalsAttributeFilters = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CYCLE_FILTER_ATTRIBUTES: {
      const { data, goalType, goalTypeId } = action.payload;
      const { cycle_view } = state;
      const newState = {
        ...state,
        cycle_view: {
          ...cycle_view,
          [goalType]: {
            ...cycle_view?.[goalType],
            [goalTypeId]: { ...cycle_view?.[goalType]?.[goalTypeId], ...data },
          },
        },
      };
      return newState;
    }

    case REMOVE_CYCLE_FILTERS: {
      const { goalType, goalTypeId, cycleId } = action.payload;
      const newState = { ...state };
      delete newState.cycle_view?.[goalType]?.[goalTypeId]?.[cycleId];
      return newState;
    }
    default:
      return state;
  }
};

export default goalsAttributeFilters;
