import {
  SET_EXPANDED_KEYS_STORE,
  SET_CHECKIN_CARD_ID,
  REMOVE_EXPANDED_KEYS_STORE,
  ADD_EXPANDED_KEYS_STORE,
  RESET_EXPANDED_KEYS_STORE,
  ADD_LOADING_KEY,
  REMOVE_LOADING_KEY,
  SET_SELECTED_CYCLE_ID,
  SET_RECENT_KEY_RESULT_ID,
  SET_SYNC_PROJECT_STATUS,
  SET_UPDATED_ALIGN_PARENT_NODE_ID,
  SET_ALIGN_PREVIOUS_PARENT_NODE_ID,
  SET_CREATE_GOAL_DETAIL_PANEL,
  SET_CHECKIN_DRAGGER_VARIABLE,
  SET_OPEN_GOALS_OKR_POPUP,
} from "actions/Goals/GoalsMetaData/GoalsMetaData.action";

const INITIAL_STATE = {
  expandedKeysStore: {},
  checkinCardId: null,
  loadingKeys: {},
  selectedCycleId: null,
  recentKeyResultId: null,
  openGoalsOkrPopup: false,
};

const goalsMetaDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXPANDED_KEYS_STORE: {
      const { cardId, id, expandedKeys } = action.payload;
      return {
        ...state,
        expandedKeysStore: {
          ...state.expandedKeysStore,
          [cardId]: {
            ...(state.expandedKeysStore[cardId] || {}),
            [id]: expandedKeys,
          },
        },
      };
    }

    case RESET_EXPANDED_KEYS_STORE: {
      return {
        ...state,
        expandedKeysStore: {},
      };
    }

    case ADD_EXPANDED_KEYS_STORE: {
      const { cardId, id, key } = action.payload;
      const isAlreadyAdded = state.expandedKeysStore?.[cardId]?.[id]?.some(
        (goalId) => goalId === key
      );
      if (isAlreadyAdded) {
        return state;
      }
      return {
        ...state,
        expandedKeysStore: {
          ...state.expandedKeysStore,
          [cardId]: {
            ...(state.expandedKeysStore?.[cardId] || {}),
            [id]: [...(state.expandedKeysStore?.[cardId]?.[id] || []), key],
          },
        },
      };
    }

    case REMOVE_EXPANDED_KEYS_STORE: {
      const { cardId, id, key } = action.payload;

      return {
        ...state,
        expandedKeysStore: {
          ...state.expandedKeysStore,
          [cardId]: {
            ...(state.expandedKeysStore?.[cardId] || {}),
            [id]:
              state.expandedKeysStore?.[cardId]?.[id]?.filter(
                (goalId) => goalId !== key
              ) || [],
          },
        },
      };
    }

    case SET_CHECKIN_CARD_ID: {
      return {
        ...state,
        checkinCardId: action.payload,
      };
    }

    case ADD_LOADING_KEY: {
      const { loadingKeys } = state;
      const { cardId, key } = action.payload;

      if (loadingKeys?.[cardId]?.[key]) {
        return state;
      }

      return {
        ...state,
        loadingKeys: {
          ...loadingKeys,
          [cardId]: { ...(loadingKeys?.[cardId] || {}), [key]: key },
        },
      };
    }

    case REMOVE_LOADING_KEY: {
      const { loadingKeys } = state;
      const { cardId, key } = action.payload;

      if (!loadingKeys?.[cardId]?.[key]) {
        return state;
      }

      delete loadingKeys?.[cardId]?.[key];

      return {
        ...state,
        loadingKeys,
      };
    }

    case SET_SELECTED_CYCLE_ID: {
      const { selectedCycleId } = action.payload;
      return {
        ...state,
        selectedCycleId,
      };
    }

    case SET_RECENT_KEY_RESULT_ID: {
      const { recentKeyResultId } = action.payload;
      return {
        ...state,
        recentKeyResultId,
      };
    }

    case SET_SYNC_PROJECT_STATUS: {
      const { currentStatus } = action.payload;
      return {
        ...state,
        currentStatus,
      };
    }

    case SET_UPDATED_ALIGN_PARENT_NODE_ID: {
      const { alignParentNodeId } = action.payload;
      return {
        ...state,
        alignParentNodeId,
      };
    }

    case SET_ALIGN_PREVIOUS_PARENT_NODE_ID: {
      const { alignPreviousParentNodeId } = action.payload;
      return {
        ...state,
        alignPreviousParentNodeId,
      };
    }

    case SET_CREATE_GOAL_DETAIL_PANEL: {
      return {
        ...state,
        createGoalDetailPanelId: action.payload,
      };
    }

    case SET_CHECKIN_DRAGGER_VARIABLE: {
      return {
        ...state,
        checkinDraggingThere: action.payload,
      };
    }

    case SET_OPEN_GOALS_OKR_POPUP: {
      return {
        ...state,
        openGoalsOkrPopup: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default goalsMetaDataReducer;
