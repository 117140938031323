const initialState = {
  isFetchingEmployeeData: false,
  isEmployeeCompetencyError: {},
  employeeData: {},
};

const employee = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_IS_FETCHING_EMPLOYEE_DATA": {
      return {
        ...state,
        isFetchingEmployeeData: !state.isFetchingEmployeeData,
      };
    }
    case "SET_EMPLOYEE_COMPETENCY_DATA": {
      return {
        ...state,
        employeeData: action.payload,
      };
    }
    case "SET_EMPLOYEE_COMPETENCY_ERROR": {
      return {
        ...state,
        isEmployeeCompetencyError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default employee;
