let backendHost;
let collaborativeHost;
let redirectAuthUrl;
let app_env;
let slack_client_id;
let hocusPocusHost;
let analyticsFrontendHost;
let hasAnalyticsServer = true;
let microsoftClientId;

const hostname = window && window.location && window.location.hostname;

if (hostname.includes("dev.peoplebox.ai")) {
  // Eg: dashboard-alagu.dev.peoplebox.ai
  backendHost = "https://" + hostname.replace("dashboard", "api"); // Eg: api-alagu.dev.peoplebox.ai
  collaborativeHost =
    "https://" + hostname.replace("dashboard", "collaborative"); // Eg: collaborative-alagu.dev.peoplebox.ai
  slack_client_id = "421252106081.1377854503472";
  redirectAuthUrl = "https://" + hostname + "/authComplete.html";
  app_env = "LOCALHOST";
  hasAnalyticsServer = false;
} else if (hostname.includes("localhost")) {
  backendHost = "http://localhost:3000/";
  collaborativeHost = "http://localhost:4000/";
  hocusPocusHost = "ws://0.0.0.0:1234";

  slack_client_id = "421252106081.1760774045907";
  redirectAuthUrl = "http://localhost:3001/authComplete.html";
  app_env = "LOCALHOST";
  hasAnalyticsServer = false;
} else if (hostname.includes("eu.peoplebox.ai")) {
  backendHost = "https://api.eu.peoplebox.ai";
  collaborativeHost = "https://collaborative.eu.peoplebox.ai/";
  hocusPocusHost = "wss://collaborative.eu.peoplebox.ai/hocuspocus";
  // ADDED here for help so you can change envs and use servers apis
  // backendHost = "https://api.test.peoplebox.ai";
  // collaborativeHost = "collaborative.test.peoplebox.ai";
  // hocusPocusHost = "wss://collaborative.test.peoplebox.ai/hocuspocus";
  // slack_client_id = "421252106081.1377854503472";
  redirectAuthUrl = "https://eu.peoplebox.ai/authComplete.html";
  hasAnalyticsServer = false;
  app_env = "PRODUCTION";
} else {
  // Eg: dashboard.gamma.peoplebox.ai
  backendHost = "https://" + hostname.replace("dashboard", "api"); // Eg: api.gamma.peoplebox.ai
  collaborativeHost =
    "https://" + hostname.replace("dashboard", "collaborative"); // Eg: collaborative.gamma.peoplebox.ai
  hocusPocusHost = `wss://${hostname.replace(
    "dashboard",
    "collaborative"
  )}/hocuspocus`;
  redirectAuthUrl = "https://" + hostname + "/authComplete.html";
  const appEnvironment = hostname.split(".")[1]; // Eg: ['dashboard', "gamma", "peoplebox", "ai"]
  if (appEnvironment === "peoplebox") {
    app_env = "PRODUCTION";
    slack_client_id = "421252106081.720683048096";
  } else if (appEnvironment === "beta") {
    slack_client_id = "421252106081.6722724299056";
    app_env = appEnvironment.toUpperCase();
  } else {
    slack_client_id = "421252106081.1760774045907";
    app_env = appEnvironment.toUpperCase();
  }

  if (
    app_env !== "PRODUCTION" &&
    app_env !== "DEMO" &&
    app_env !== "ANALYTICS-ALPHA" &&
    app_env !== "ANALYTICS-BETA"
  ) {
    hasAnalyticsServer = false;
  }
}

if (hostname === "dashboard.analytics-alpha.peoplebox.ai") {
  analyticsFrontendHost = "https://analytics.analytics-alpha.peoplebox.ai";
} else if (hostname === "dashboard.analytics-beta.peoplebox.ai") {
  analyticsFrontendHost = "https://analytics.analytics-beta.peoplebox.ai";
} else if (hostname.includes("localhost")) {
  analyticsFrontendHost = "http://localhost:3000";
} else if (hostname === "dashboard.peoplebox.ai") {
  analyticsFrontendHost = "https://analytics.peoplebox.ai";
} else if (hostname === "dashboard.demo.peoplebox.ai") {
  analyticsFrontendHost = "https://analytics.demo.peoplebox.ai";
} else if (hostname === "dashboard.test.peoplebox.ai") {
  analyticsFrontendHost = "https://analytics.test.peoplebox.ai";
} else {
  analyticsFrontendHost = "https://analytics.analytics-demo.peoplebox.ai";
}

let passwordEncryptionKey;

if (app_env === "PRODUCTION") {
  passwordEncryptionKey = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCM5/K4VO3qz9z4yA7zZJ30YeUO
  VvaHMFs+OAm7HFztmM3Ta1MejNd7zM1PcOQyb7Y7bi+/ZjSLfE1XIw1mvQcR1xlS
  3no5+wDrZf+82Tu1ci2WoPCq9LcFTC75/0C20lcETeZ73z4kdlNq325NaObUqQDP
  CVUhUjumEBwP7H273wIDAQAB
  -----END PUBLIC KEY-----`;
} else {
  passwordEncryptionKey = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpfgSIDv3J920OZphSkn2JMgsy
  T9+zCR2/3p2ZBsGpK0XQ+GHR822Wb6bHtMtWpJ9zJiPfqg/xrIUpvysB30Ee81kr
  wfK7Mxk2/nKzpbDORdUabZeXcT4bhC0AWKA9XkvbwoCin4oamNyx7pNZdpRg4RLe
  oO9jvUXCTKwbsDLz4wIDAQAB
  -----END PUBLIC KEY-----`;
}

if (hostname.includes("eu.peoplebox.ai")) {
  microsoftClientId = "13d3f26f-1307-4fba-a760-8cfb5766ffd1";
} else {
  microsoftClientId = "961a80e4-d1ca-4d6c-9129-6214294dc892";
}

window.API_ROOT = `${backendHost}`;
export const API_ROOT = `${backendHost}`;
export const COLLABORATIVE_HOST = collaborativeHost;
export const ANALYTICS_FE_HOST = analyticsFrontendHost;
export const GOOGLE_CLIENT_ID =
  "254391401505-l2lh09m9rabokaqbijkla8q98k552qo5.apps.googleusercontent.com";
export const MICROSOFT_CLIENT_ID = microsoftClientId;
export const SLACK_CLIENT_ID = slack_client_id;
export const CHATWOOT_TOKEN = "mi3ekGh7JfDvz4Kggwr2WchX";
export const redirectUri = redirectAuthUrl;
export const APP_ENV = app_env;
export const HOCUS_POCUS_HOST = `${hocusPocusHost}`;
export const PASSWORD_ENCRYPTION_KEY = passwordEncryptionKey;
export const HAS_ANALYTICS_SERVER = hasAnalyticsServer;
export const REGION_URLS = {
  global: "https://dashboard.peoplebox.ai",
  eu: "https://eu.peoplebox.ai",
};
export const CURRENT_REGION =
  window.location.hostname.split(".")[0] === "dashboard"
    ? "global"
    : window.location.hostname.split(".")[0];

// export const HAS_ANALYTICS_SERVER = [
//   "PRODUCTION",
//   "ANALYTICS-ALPHA",
//   "ANALYTICS-BETA",
// ].includes(APP_ENV);
