import {
  GET_GOALS_IDS_LIST,
  GET_GOAL_SHOW,
  UPDATE_TREE_STRUCTURE_NODE,
  DELETE_GOAL_ID_LIST,
  UPDATE_GOAL_IDS_LIST,
  UPDATE_GOAL_CYCLE_DATA,
  UPDATE_GOAL_CYCLE_LOADING,
  UPDATE_GOAL_CYCLE_ERROR,
  UPDATE_GOAL_ID_LOADING,
  UPDATE_GOAL_IDS_BY_PRIORITY,
  CLEAN_OLD_PARENT_ID_FROM_CYCLE_GOAL_LIST,
  ADD_PARENT_ID_TO_CYCLE_GOAL_LIST,
  UPDATE_CYCLE_PAGINATION_REMAINING,
  UPDATE_CYCLE_SHOW_PROGRESS_BREAKDOWN,
} from "actions/constants";
import { uniq } from "lodash";

const selectedTimelineView =
  localStorage.getItem("timeline-view") || "quarters";

const initialState = {
  loading: true,
  activeGoaltype: null,
  activeGoaltypeId: null,
  updatedParentNodeId: null,
  updatedParentNodeCount: 0,
  selectedTimelineView,
};

const manageDataUpdate = (state, payload) => {
  const { activeGoaltype, activeGoaltypeId, data } = payload;
  if (state[activeGoaltype]?.[activeGoaltypeId]) {
    return {
      [activeGoaltype]: {
        ...state[activeGoaltype],
        [activeGoaltypeId]: {
          quarters: uniq([
            ...state[activeGoaltype][activeGoaltypeId].quarters,
            ...data.quarters,
          ]),
          goalsIds: uniq([
            ...state[activeGoaltype][activeGoaltypeId].goalsIds,
            ...data.goalsIds,
          ]),
          goalsIdsGroupedByTimeline: {
            ...state[activeGoaltype][activeGoaltypeId]
              .goalsIdsGroupedByTimeline,
            [data.quarters[0]]: state[activeGoaltype][activeGoaltypeId]
              .goalsIdsGroupedByTimeline[data.quarters[0]]
              ? [
                  ...state[activeGoaltype][activeGoaltypeId]
                    .goalsIdsGroupedByTimeline[data.quarters[0]],
                  ...data.goalsIdsGroupedByTimeline[data.quarters[0]],
                ]
              : [...data.goalsIdsGroupedByTimeline[data.quarters[0]]],
          },
        },
      },
    };
  } else {
    return {
      [activeGoaltype]: {
        ...state[activeGoaltype],
        [activeGoaltypeId]: { ...payload.data },
      },
    };
  }
};

const goalsIdsList = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GOAL_ID_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_GOALS_IDS_LIST: {
      const { activeGoaltype, activeGoaltypeId, loading } = action.payload;
      const newState = Object.assign({}, state, {
        loading,
        activeGoaltype,
        activeGoaltypeId,
        [activeGoaltype]: {
          ...state[activeGoaltype],
          [activeGoaltypeId]: {
            goalsIds: [
              ...new Set([
                ...(state[activeGoaltype]?.[activeGoaltypeId]?.goalsIds || []),
                ...action.payload.data.goalsIds,
              ]),
            ],
            goalsIdsByCycles: {
              active: {
                ...(state[activeGoaltype]?.[activeGoaltypeId]?.goalsIdsByCycles
                  ?.active || {}),
                ...action.payload.data.goalsIdsByCycles.active,
              },
              upcoming: {
                ...(state[activeGoaltype]?.[activeGoaltypeId]?.goalsIdsByCycles
                  ?.upcoming || {}),
                ...action.payload.data.goalsIdsByCycles.upcoming,
              },
              previous: {
                ...(state[activeGoaltype]?.[activeGoaltypeId]?.goalsIdsByCycles
                  ?.previous || {}),
                ...action.payload.data.goalsIdsByCycles.previous,
              },
            },
          },
        },
      });
      return newState;
    }

    case UPDATE_GOAL_IDS_BY_PRIORITY: {
      const { cycleType, cycleId, activeGoalType, activeGoalTypeId, ids } =
        action.payload;

      return {
        ...state,
        [activeGoalType]: {
          ...state?.[activeGoalType],
          [activeGoalTypeId]: {
            ...state?.[activeGoalType]?.[activeGoalTypeId],
            goalsIdsByCycles: {
              ...state?.[activeGoalType]?.[activeGoalTypeId]?.goalsIdsByCycles,
              [cycleType]: {
                ...state?.[activeGoalType]?.[activeGoalTypeId]
                  ?.goalsIdsByCycles?.[cycleType],
                [cycleId]: {
                  ...state?.[activeGoalType]?.[activeGoalTypeId]
                    ?.goalsIdsByCycles?.[cycleType]?.[cycleId],
                  goals: [...ids],
                },
              },
            },
          },
        },
      };
    }

    case GET_GOAL_SHOW: {
      const { activeGoaltype, activeGoaltypeId, loading } = action.payload;
      const updatedData = manageDataUpdate(state, action.payload);
      const newState = Object.assign({}, state, {
        loading,
        activeGoaltype,
        activeGoaltypeId,
        ...updatedData,
      });
      return newState;
    }

    case UPDATE_TREE_STRUCTURE_NODE: {
      return {
        ...state,
        updatedParentNodeId: action.payload,
        updatedParentNodeCount: state.updatedParentNodeCount + 1,
      };
    }

    case DELETE_GOAL_ID_LIST: {
      const {
        goalId,
        goalType,
        goalTypeId,
        goalsChildrenIds,
        goalChildrenObject,
      } = action.payload;

      const updatedActiveCycles = {};
      const updatedUpcomingCycles = {};
      const updatedPreviousCycles = {};

      Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.active).length &&
        Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.active).map(
          (key, index) => {
            updatedActiveCycles[key] = {
              ...state[goalType][goalTypeId].goalsIdsByCycles.active[key],
              goals: state[goalType][goalTypeId].goalsIdsByCycles.active[
                key
              ].goals.filter((value) => value.toString() !== goalId),
            };
          }
        );

      Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.upcoming)
        .length &&
        Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.upcoming).map(
          (key, index) => {
            updatedUpcomingCycles[key] = {
              ...state[goalType][goalTypeId].goalsIdsByCycles.upcoming[key],
              goals: state[goalType][goalTypeId].goalsIdsByCycles.upcoming[
                key
              ].goals.filter((value) => value.toString() !== goalId),
            };
          }
        );

      Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.previous)
        .length &&
        Object.keys(state[goalType][goalTypeId].goalsIdsByCycles.previous).map(
          (key, index) => {
            updatedPreviousCycles[key] = {
              ...state[goalType][goalTypeId].goalsIdsByCycles.previous[key],
              goals: state[goalType][goalTypeId].goalsIdsByCycles.previous[
                key
              ].goals.filter((value) => value.toString() !== goalId),
            };
          }
        );

      if (
        Object.keys(goalChildrenObject).length !== 0 &&
        goalsChildrenIds.length === 0
      ) {
        Object.keys(goalChildrenObject).map((k, i) => {
          if (goalChildrenObject[k]?.goal_cycle?.state === "upcoming") {
            if (Object.keys(updatedUpcomingCycles).length === 0) {
            } else {
              if (
                updatedUpcomingCycles[goalChildrenObject[k]?.goal_cycle?.id]
                  .goals
              ) {
                const arr = updatedUpcomingCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals.filter((val) => val !== parseInt(k));
                if (arr) {
                  updatedUpcomingCycles[
                    goalChildrenObject[k]?.goal_cycle?.id
                  ].goals = arr;
                }
              }
            }
          }
          if (goalChildrenObject[k]?.goal_cycle?.state === "previous") {
            if (Object.keys(updatedPreviousCycles).length === 0) {
            } else {
              if (
                updatedPreviousCycles[goalChildrenObject[k]?.goal_cycle?.id]
                  .goals
              ) {
                const arr = updatedPreviousCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals.filter((val) => val !== parseInt(k));
                if (arr) {
                  updatedPreviousCycles[
                    goalChildrenObject[k]?.goal_cycle?.id
                  ].goals = arr;
                }
              }
            }
          }
        });
      }

      if (goalsChildrenIds.length) {
        Object.keys(goalChildrenObject).map((k, i) => {
          if (goalChildrenObject[k]?.goal_cycle?.state === "active") {
            if (updatedActiveCycles[goalChildrenObject[k]?.goal_cycle?.id]) {
              if (
                updatedActiveCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals.indexOf(parseInt(k)) === -1
              ) {
                updatedActiveCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals.push(parseInt(k));
              }
            } else {
              if (updatedActiveCycles[goalChildrenObject[k]?.goal_cycle?.id]) {
                updatedActiveCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals = [parseInt(k)];
              }
            }
          }
          if (goalChildrenObject[k]?.goal_cycle?.state === "upcoming") {
            if (
              updatedUpcomingCycles[goalChildrenObject[k]?.goal_cycle?.id]
                ?.goals
            ) {
              const arr =
                updatedUpcomingCycles[goalChildrenObject[k]?.goal_cycle?.id]
                  .goals;
              if (arr.indexOf(parseInt(k)) === -1) {
                arr.push(parseInt(k));
              }

              updatedUpcomingCycles[
                goalChildrenObject[k]?.goal_cycle?.id
              ].goals = arr.filter((item, i, ar) => ar.indexOf(item) === i);
            } else {
              if (
                updatedUpcomingCycles[goalChildrenObject[k]?.goal_cycle?.id]
              ) {
                updatedUpcomingCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals = [parseInt(k)];
              }
            }
          }
          if (goalChildrenObject[k]?.goal_cycle?.state === "previous") {
            if (
              updatedPreviousCycles[goalChildrenObject[k]?.goal_cycle?.id]
                ?.goals
            ) {
              const arr =
                updatedPreviousCycles[goalChildrenObject[k]?.goal_cycle?.id]
                  .goals;
              if (arr.indexOf(parseInt(k)) === -1) {
                arr.push(parseInt(k));
              }

              updatedPreviousCycles[
                goalChildrenObject[k]?.goal_cycle?.id
              ].goals = arr.filter((item, i, ar) => ar.indexOf(item) === i);
            } else {
              if (
                updatedPreviousCycles[goalChildrenObject[k]?.goal_cycle?.id]
              ) {
                updatedPreviousCycles[
                  goalChildrenObject[k]?.goal_cycle?.id
                ].goals = [parseInt(k)];
              }
            }
          }
        });
      }
      let updatedGoalIdsArray = state[goalType][goalTypeId].goalsIds.filter(
        (value) => {
          if (
            Object.keys(goalChildrenObject).length !== 0 &&
            goalsChildrenIds.length === 0
          ) {
            return (
              value !== goalId &&
              !Object.keys(goalChildrenObject).includes(value.toString())
            );
          }
          return value !== goalId;
        }
      );
      updatedGoalIdsArray = [...updatedGoalIdsArray, ...goalsChildrenIds];

      const newState = Object.assign({}, state, {
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            ...state[goalType][goalTypeId],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              active:
                Object.keys(updatedActiveCycles).length !== 0
                  ? { ...updatedActiveCycles }
                  : {},
              upcoming:
                Object.keys(updatedUpcomingCycles).length !== 0
                  ? { ...updatedUpcomingCycles }
                  : {},
              previous:
                Object.keys(updatedPreviousCycles).length !== 0
                  ? { ...updatedPreviousCycles }
                  : {},
            },
            goalsIds: [...updatedGoalIdsArray],
          },
        },
      });

      return newState;
    }

    case UPDATE_GOAL_IDS_LIST: {
      const { activeGoaltype, activeGoaltypeId, loading } = action.payload;
      const newState = Object.assign({}, state, {
        loading,
        activeGoaltype,
        activeGoaltypeId,
        [activeGoaltype]: {
          ...state[activeGoaltype],
          [activeGoaltypeId]: {
            goalsIds: [
              ...new Set([
                ...(state[activeGoaltype]?.[activeGoaltypeId]?.goalsIds || []),
                ...action.payload.data.goalsIds,
              ]),
            ],
            goalsIdsByCycles: {
              active: action.payload.data.goalsIdsByCycles.active
                ? { ...action.payload.data.goalsIdsByCycles.active }
                : {
                    ...state[activeGoaltype]?.[activeGoaltypeId]
                      .goalsIdsByCycles.active,
                  },
              upcoming: action.payload.data.goalsIdsByCycles.upcoming
                ? { ...action.payload.data.goalsIdsByCycles.upcoming }
                : {
                    ...state[activeGoaltype]?.[activeGoaltypeId]
                      .goalsIdsByCycles.upcoming,
                  },
              previous: action.payload.data.goalsIdsByCycles.previous
                ? { ...action.payload.data.goalsIdsByCycles.previous }
                : {
                    ...state[activeGoaltype]?.[activeGoaltypeId]
                      .goalsIdsByCycles.previous,
                  },
            },
          },
        },
      });
      return newState;
    }

    case UPDATE_GOAL_CYCLE_DATA: {
      const {
        goalType,
        goalTypeId,
        allGoalIdList,
        cycleType,
        cycleData,
        goalIdList,
        cycleId,
      } = action.payload;

      return {
        ...state,
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            goalsIds: [
              ...new Set([
                ...state[goalType][goalTypeId].goalsIds,
                ...allGoalIdList,
              ]),
            ],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              [cycleType]: {
                ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType],
                [cycleId]: {
                  ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                    cycleId
                  ],
                  ...cycleData,
                  goals: [
                    ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                      cycleId
                    ].goals,
                    ...goalIdList,
                  ],
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_GOAL_CYCLE_LOADING: {
      const { goalType, goalTypeId, cycleType, cycleId, loading } =
        action.payload;
      return {
        ...state,
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            ...state[goalType][goalTypeId],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              [cycleType]: {
                ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType],
                [cycleId]: {
                  ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                    cycleId
                  ],
                  loading,
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_GOAL_CYCLE_ERROR: {
      const { goalType, goalTypeId, cycleType, cycleId, error } =
        action.payload;
      return {
        ...state,
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            ...state[goalType][goalTypeId],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              [cycleType]: {
                ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType],
                [cycleId]: {
                  ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                    cycleId
                  ],
                  loading: false,
                  error,
                },
              },
            },
          },
        },
      };
    }

    case CLEAN_OLD_PARENT_ID_FROM_CYCLE_GOAL_LIST: {
      const { goalType, goalTypeId, goalId, goalCycleId } = action.payload;
      const { goalsIdsByCycles } = state[goalType][goalTypeId];

      const goalCycleType = Object.keys(goalsIdsByCycles).find(
        (cycleType) => goalCycleId in goalsIdsByCycles[cycleType]
      );

      const cycleGoals = [
        ...state[goalType][goalTypeId].goalsIdsByCycles[goalCycleType][
          goalCycleId
        ].goals,
      ];

      const goalIndex = cycleGoals.findIndex(
        (id) => id.toString() === goalId.toString()
      );

      cycleGoals.splice(goalIndex, 1);

      state[goalType][goalTypeId].goalsIdsByCycles[goalCycleType][
        goalCycleId
      ].goals = cycleGoals;

      return state;
    }

    case ADD_PARENT_ID_TO_CYCLE_GOAL_LIST: {
      const { goalType, goalTypeId, goalId, goalCycleId } = action.payload;
      const { goalsIdsByCycles } = state[goalType][goalTypeId];

      const goalCycleType = Object.keys(goalsIdsByCycles).find(
        (cycleType) => goalCycleId in goalsIdsByCycles[cycleType]
      );

      const cycleGoals = [
        ...state[goalType][goalTypeId].goalsIdsByCycles[goalCycleType][
          goalCycleId
        ].goals,
      ];

      if (!cycleGoals.find((g) => g.toString() === goalId.toString())) {
        cycleGoals.unshift(goalId);

        state[goalType][goalTypeId].goalsIdsByCycles[goalCycleType][
          goalCycleId
        ].goals = cycleGoals;
      }

      return state;
    }

    case UPDATE_CYCLE_PAGINATION_REMAINING: {
      const { filters, values } = action.payload;
      const { cycleType, cycleId, goalType, goalTypeId } = filters;

      const newState = {
        ...state,
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            ...state[goalType][goalTypeId],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              [cycleType]: {
                ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType],
                [cycleId]: {
                  ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                    cycleId
                  ],
                  ...values,
                },
              },
            },
          },
        },
      };

      return newState;
    }

    case UPDATE_CYCLE_SHOW_PROGRESS_BREAKDOWN: {
      const {
        cycleType,
        cycleId,
        goalType,
        goalTypeId,
        showProgressBreakdown,
      } = action.payload;

      const newState = {
        ...state,
        [goalType]: {
          ...state[goalType],
          [goalTypeId]: {
            ...state[goalType][goalTypeId],
            goalsIdsByCycles: {
              ...state[goalType][goalTypeId].goalsIdsByCycles,
              [cycleType]: {
                ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType],
                [cycleId]: {
                  ...state[goalType][goalTypeId].goalsIdsByCycles[cycleType][
                    cycleId
                  ],
                  show_progress_breakdown: showProgressBreakdown,
                },
              },
            },
          },
        },
      };

      return newState;
    }

    default:
      return state;
  }
};

export default goalsIdsList;
